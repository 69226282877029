@import '../../../estee_base/scss/_theme-bootstrap';

#colorbox.overlay--interstitial {
  #cboxClose {
    background: $sprites-sprites no-repeat 0 -973px !important;
    height: 28px;
    position: absolute;
    #{$rdirection}: 12px;
    width: 28px;
    @media #{$medium-up} {
      #{$rdirection}: 16px;
      top: 15px;
    }
  }
  .interstitial-language {
    &__button {
      padding: 20px 0;
    }
    &__subtext,
    &__text {
      padding-bottom: 10px;
    }
    &__link--link {
      width: 100%;
      @media #{$medium-up} {
        width: auto;
      }
    }
    &__header {
      letter-spacing: -0.01em;
    }
  }
  hr {
    border-top: 1px solid $color-light-gray;
  }
  .device-mobile & {
    height: 420px !important;
  }
}
